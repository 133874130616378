<template>
  <q-page class="flex flex-center auth">
    <q-card
      :class="$q.platform.is.mobile ? 'q-pa-sm transparent' : 'q-pa-md'"
      flat
      :bordered="!$q.platform.is.mobile"
      :style="$q.platform.is.mobile ? `width:${$q.screen.width}px` : 'width:420px'"
    >
      <q-card-section class="text-center q-pt-md q-pb-none">
        <q-img
          :src="require('@/assets/images/logo_color.png')"
          spinner-color="white"
          width="168px"
          spinner-size="82px"
        />
      </q-card-section>

      <q-card-section>
        <p>Você foi removido com sucesso e não receberá mais emails.</p>

        <p>Para se inscrever novamente acesse a plataforma em: Minha Conta > Preferências.</p>
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script>
export default {};
</script>

<style></style>
